.logo {
    display: block;
    width: max-content;
    z-index: 999999;
    justify-self: left;
    grid-column: 1/2;
    grid-row: auto;
    padding-top: 8vw;
    /* padding: 1.5vw; */
    padding-left: 5vw;
  }

  .logo .logo-position {
    margin-top: 1.75vw;
    width: 25vw;
  }
  
  @media screen and (min-width: 1001px) {
    .logo {
      padding: 0vw;
      padding-left: 5vw;
      margin-top: 1.5vw;
    }

    .logo .logo-position {
      display: block;
      width: 12vw;
      margin-top: 0vw;
    }
  }
  