input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}

.fullPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 26vw;
  font-family: "Roboto", "Poppins", sans-serif;
  height: 100%;
}

.fullPage .top-bar {
  display: grid;
  background-color: #0F2835;
  grid-template-rows: 1fr;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  z-index: 99;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25%));
  padding-bottom: 6vw;
}

.fullPage .home-position{
  grid-column: 1/1;
  grid-row: 2/2;
  height: 100%;
  margin-top: 0%;
  margin-left: -5%;
}

@media screen and (min-width: 1001px) {
  .fullPage {
    grid-template-rows: 10vw 100%;
    width: 100%;
    height: 100%;
  }

  .fullPage .top-bar {
    transition: 0.4s;
    position: fixed;
    width: 100%;
    z-index: 99;
    overflow: hidden;
    grid-column: 1/3;
    display: grid;
    grid-template-rows: 1fr;
    grid-row: 1/1;
    padding-bottom: 1vw;
  }
  
  .fullPage .home-position{
    grid-column: 1/1;
    grid-row: 2/2;
    height: 80%;
    margin-top: -3%;
    margin-left: 0%;
  }
}
