.fullpage-topbar{
    text-align: justify;
    width: 100%;
    font-family: "Inter", sans-serif;
  }

  .fullpage-topbar .top-bar {
    display: grid;
    background-color: #000;
    grid-template-rows: 1fr;
    transition: 0.4s;
    position: fixed;
    width: 100%;
    z-index: 99;
    overflow: hidden;
    filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25%));
    padding-bottom: 5vw;
  }

  .container-topbar{
    padding: 0% 10% 0% 10%;
    color: #000;
  }

  .container-topbar h2{
    font-weight: 500;
    text-align: left;
    color: #000;
  }
  .container-topbar h4{
    font-weight: 500;
    color: #000;
  }

  .container-topbar .policies-title {
    font-weight: 600;
    font-size: 10vw;
    color: #000;
  }

  @media screen and (min-width: 1001px) { 
    .container-topbar h2{
      font-weight: 500;
      font-size: 180%;
    }
  
    .fullpage-topbar .top-bar {
      transition: 0.4s;
      position: fixed;
      width: 100%;
      z-index: 99;
      overflow: hidden;
      grid-column: 1/3;
      display: grid;
      grid-template-rows: 1fr;
      grid-row: 1/1;
      height: 1.5vw;
    }

    .container-topbar .policies-title {
      font-size: 290%;
    }
  }